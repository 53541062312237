import { mediaQueries, size, H3, H4, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import SectionTitle from '../../Enterprise/SectionTitle';

export const Title = styled(SectionTitle)`
  padding-top: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  }
`;

export const Headline = styled(H3)`
  margin-bottom: ${size.md}px;
  font-weight: normal;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;

export const SubHeadline = styled(H4)`
  ${typography.subtitle}
  margin-bottom: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;

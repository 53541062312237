import { typography, size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Title = styled.p`
  ${typography.overlineText};
  margin-bottom: ${size.sm}px;
  text-transform: uppercase;
  text-align: left;
  font-weight: ${typography.weight.xbold};
  ${mediaQueries.forTabletHorizontalUp} {
    text-align: center;
  }
`;

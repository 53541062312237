import { Row, Col, Container } from '@everlywell/leaves';
import { MarkdownContent } from 'components/Contentful/MarkdownContent';
import React from 'react';

import SectionWrapper from './../../SectionWrapper';
import * as S from './styles';

export interface MarkdownSectionProps
  extends React.HTMLAttributes<HTMLElement> {
  title?: string;
  headline?: string;
  subHeadline?: string;
  body: string;
  className?: string;
}

export const MarkdownContentSection: React.FC<MarkdownSectionProps> = ({
  title,
  headline,
  subHeadline,
  body,
  className,
  ...rest
}) => (
  <SectionWrapper className={className} {...rest}>
    <Container>
      <Row>
        <Col>
          {title ? <S.Title text={title} /> : null}
          {headline ? <S.Headline>{headline}</S.Headline> : null}
          {subHeadline ? <S.SubHeadline>{subHeadline}</S.SubHeadline> : null}
          {body ? <MarkdownContent body={body} /> : null}
        </Col>
      </Row>
    </Container>
  </SectionWrapper>
);

export default MarkdownContentSection;

import React from 'react';

import { PageSectionType } from 'utils/types';
import { AccessibilitySection } from '../../components/Accessibility';

export interface AccessibilityContainerProps {
  content: {
    sections: PageSectionType[];
  };
}

export const AccessibilityContainer: React.FC<AccessibilityContainerProps> = ({
  content,
}) => {
  if (!content) return null;

  const { sections } = content;
  const { headline, description, desktopImages } = sections[0];

  if (!headline || !description || !desktopImages) return null;

  const props = {
    headline,
    description,
    image: desktopImages[0]?.file?.url,
  };

  return <AccessibilitySection {...props} />;
};

export default AccessibilityContainer;

import { Row } from '@everlywell/leaves';
import { Section } from 'components/SectionWrapper';
import React from 'react';

import * as S from './styles';

export interface AccessibilitySectionProps {
  headline: string;
  image?: string;
  description: {
    childMarkdownRemark?: {
      html: string;
    };
    description: string;
  };
}

export const AccessibilitySection: React.FC<AccessibilitySectionProps> = ({
  headline,
  description,
  image,
}) => (
  <Section data-testid="accessibilitySection">
    <S.Container>
      <Row center="xs" around="lg">
        <S.Col xs={12} lg={12}>
          <S.ImageWrapper>
            {image ? (
              <S.BrandGraphic
                src={image}
                alt="brand graphic"
                role="presentation"
              />
            ) : null}
          </S.ImageWrapper>
          {headline ? (
            <S.Headline tabIndex={0} role={'heading'}>
              {headline}
            </S.Headline>
          ) : null}
          <S.MarkdownContent
            tabIndex={0}
            role="text"
            body={description?.childMarkdownRemark?.html || ''}
          />
        </S.Col>
      </Row>
    </S.Container>
  </Section>
);

export default AccessibilitySection;

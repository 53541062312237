import React from 'react';

import * as S from './styles';

interface Props {
  text: string;
  className?: string;
}
export const SectionTitle: React.FC<Props> = ({ text, className }) => {
  return <S.Title className={className}>{text}</S.Title>;
};

export default SectionTitle;

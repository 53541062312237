import { graphql } from 'gatsby';
import React from 'react';
import { PageSectionType } from 'utils/types';
import Layout from '../components/Layout';
import AccessibilityContainer from '../containers/AccessibilityContainer';

type Props = {
  data: {
    content: {
      sections: PageSectionType[];
      title: string;
    };
  };
};

const AccessibilityPage = (props: Props): React.ReactElement<Props> | null => {
  const { data } = props;
  const { content } = data;
  return (
    <Layout>
      <AccessibilityContainer content={content} />
    </Layout>
  );
};

export default AccessibilityPage;

export const data = graphql`
  query {
    content: contentfulPage(pageId: { eq: "Accessibility Page" }) {
      title
      sections {
        componentName
        title
        slug
        headline
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        desktopImages {
          file {
            url
          }
          description
        }
      }
    }
  }
`;

import {
  Col as RawCol,
  Container as RawContainer,
  H2,
  mediaQueries,
  size,
  colors,
  typography,
} from '@everlywell/leaves';
import RawMarkdownContent from 'components/PageSections/MarkdownContent';
import styled from 'styled-components';

export const BrandGraphic = styled.img`
  width: ${size.xl3}px;
  height: ${size.xl3}px;
`;

export const Container = styled(RawContainer)`
  margin: ${size.xl2}px auto;

  ${mediaQueries.forTabletVerticalUp} {
    &.container {
      max-width: 60%;
    }

    margin: ${size.xl6}px auto;
  }
`;

export const Headline = styled(H2)`
  margin: ${size.lg}px 0;
`;

export const Col = styled(RawCol)`
  align-items: center;
`;

export const MarkdownContent = styled(RawMarkdownContent)`
  padding-top: 0;
  text-align: left;

  .container,
  .row {
    padding: 0;
    margin: 0;
  }

  strong {
    font-weight: ${typography.weight.bold};
  }

  a {
    color: ${colors.green4};
    text-decoration: none;
    font-weight: ${typography.weight.bold};
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${colors.teal4};
    }
  }

  p {
    margin-bottom: ${size.xs1}px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
